import React, { useState, useMemo } from 'react'
import { useHistory, generatePath } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import {
    IStoreHeader,
    IStoreGoods,
    IStoreFolder,
    IStoreProfile,
    IStoreCurrency,
    IGoods,
    IGoodsData,
} from 'interfaces'
import { TBasketUpdateProps } from 'services/MarketService'
import { CardSize } from 'enums'
import { APP_URL, PRODUCT_TYPE_PROMOTION_DESCRIPTION, BREAKPOINTS } from 'config/app'
import { useFetchBasket, useMutationBasket } from 'containers/Market/hooks'
import { useBasketGoods } from 'containers/StoreBasket/hooks'
import {
    Loader,
    Modal,
    Button,
    ErrorMsg,
    Iframe,
    CatalogShowcase,
} from 'components'
import { getStoreCurrency, showAlertNotify } from 'utils/helpers'
import { MarketService } from 'services'
import { useWindowResize } from 'hooks'
import style from './Catalog.module.css'

type CatalogPropType = {
    classes?: string
    isLoading?: boolean
    isShowEmptyText?: boolean
    isSetItemLink: boolean
    isShowBuyButton: boolean
    storeProfile: IStoreProfile
    data?: (IStoreHeader | IStoreFolder | IStoreGoods | IGoods)[]
    onClickFolder?: (value: IStoreHeader | IStoreFolder) => void
    onClickGoods?: (value: IGoodsData) => void
}

/**
 * Catalog - user view store catalog
 */
const Catalog: React.FC<CatalogPropType> = ({
    classes,
    isLoading = false,
    isShowEmptyText = true,
    isSetItemLink,
    isShowBuyButton,
    storeProfile,
    data,
    onClickFolder,
    onClickGoods,
}) => {
    const { t } = useTranslation()
    const history = useHistory()
    const [windowWidth] = useWindowResize()

    const [goodsIframe, setGoodsIframe] = useState<IGoodsData>()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isOpenGoodsModalIframe, setIsOpenGoodsModalIframe] = useState(false)

    const { data: dataBasket } = useFetchBasket({ storeId: storeProfile.id }, { enabled: !!storeProfile.id }) // !!dataStoreProfile

    const { goodsInBasketDefaultPrice } = useBasketGoods({ basket: dataBasket, goodsId: goodsIframe?.id })

    const { update: updateBasket } = useMutationBasket()

    const storeCurrency: IStoreCurrency | undefined = useMemo(() => {
        return storeProfile ? getStoreCurrency(storeProfile) : undefined
    }, [storeProfile])

    const payoutCurrency = useMemo(() => {
        return MarketService.getPayoutCurrency(dataBasket?.market_wallet_list)
    }, [dataBasket])

    const itemsSize = useMemo(() => {
        if (windowWidth > BREAKPOINTS.tabletLandscape) {
            return CardSize.thirdWidth
        }
        return CardSize.halfWidth
    }, [windowWidth])

    const handlerCloseModalGoodsIframe = () => {
        setGoodsIframe(undefined)
        setIsOpenGoodsModalIframe(false)
    }

    const handlerClickGoods = (value: IGoodsData) => {
        if (isSetItemLink && value.landing_link) {
            setGoodsIframe(value)
            setIsOpenGoodsModalIframe(true)
        }
        if (onClickGoods) {
            onClickGoods(value)
        }
    }

    const handlerAddGoodsToBasket = ({ id }: IGoodsData, count: number, paymentCurrency?: string) => {
        addToBasketAction(id, count, paymentCurrency)
    }

    const handlerAddLandingGoodsToBasket = () => {
        if (goodsInBasketDefaultPrice?.quantity) {
            history.push(generatePath(APP_URL.basket, { id: storeProfile.id }))
        } else if (goodsIframe) {
            addToBasketAction(goodsIframe.id, 1)
        }
    }

    const generateCatalogUrl = (value: IStoreHeader | IStoreFolder) => {
        return isSetItemLink
            ? generatePath(APP_URL.catalog, { id: storeProfile.id, catalogId: value.id })
            : undefined
    }

    const generateGoodsUrl = (value: IGoodsData) => {
        return isSetItemLink && !value.landing_link
            ? generatePath(APP_URL.goods, { id: value.id })
            : undefined
    }

    function addToBasketAction(goodsId: number, count: number, paymentCurrency?: string) {
        const params: TBasketUpdateProps = {
            storeId: storeProfile.id,
            goods: goodsId,
            quantity: count,
        }

        if (paymentCurrency) {
            params.payment_by = paymentCurrency
        }

        setIsSubmitting(true)
        updateBasket.mutate(params, {
            onSuccess: (res) => {
                if (res?.warning_list?.length) {
                    showAlertNotify({
                        type: 'error',
                        message: res.warning_list[0].description || t('update_error'),
                    })
                }
            },
            onError: (err) => {
                showAlertNotify({ type: 'error', message: err[0] })
            },
            onSettled: () => {
                setIsSubmitting(false)
                setIsOpenGoodsModalIframe(false)
            },
        })
    }

    return (
        <>
            {isLoading && (
                <Loader />
            )}

            {!isLoading && isShowEmptyText && data && !data.length && (
                <ErrorMsg error={t('goods_not_found')} />
            )}

            {!isLoading && data && (
                <CatalogShowcase classes={cn(style.catalog, classes)}>
                    {data.map((item) => (
                        <React.Fragment key={'type' in item && item.type === 'goods' ? item.container.id : item.id}>
                            {('type' in item) ? (
                                <>
                                    {item.type === 'header' && (
                                        <CatalogShowcase.Header
                                            data={item}
                                            url={generateCatalogUrl(item)}
                                            onClick={onClickFolder}
                                        />
                                    )}
                                    {item.type === 'folder' && (
                                        <CatalogShowcase.Folder
                                            data={item}
                                            size={itemsSize}
                                            url={generateCatalogUrl(item)}
                                            onClick={onClickFolder}
                                        />
                                    )}
                                    {item.type === 'goods' && (
                                        <CatalogShowcase.Goods
                                            data={item.container}
                                            size={itemsSize}
                                            url={generateGoodsUrl(item.container)}
                                            basket={dataBasket}
                                            storeCurrency={storeCurrency}
                                            payoutCurrency={payoutCurrency}
                                            onClick={isSetItemLink || onClickGoods ? handlerClickGoods : undefined}
                                            onAddToBasket={isShowBuyButton ? handlerAddGoodsToBasket : undefined}
                                        />
                                    )}
                                </>
                            ) : (
                                <CatalogShowcase.Goods
                                    data={item}
                                    size={itemsSize}
                                    url={generateGoodsUrl(item)}
                                    basket={dataBasket}
                                    storeCurrency={storeCurrency}
                                    payoutCurrency={payoutCurrency}
                                    onClick={isSetItemLink || onClickGoods ? handlerClickGoods : undefined}
                                    onAddToBasket={isShowBuyButton ? handlerAddGoodsToBasket : undefined}
                                />
                            )}
                        </React.Fragment>
                    ))}
                </CatalogShowcase>
            )}

            <Modal
                isOpen={isOpenGoodsModalIframe}
                classes={style.modalIframe}
                classesOverlay={style.modalIframeOverlay}
                size="large"
                onClose={handlerCloseModalGoodsIframe}
            >
                <Modal.Header isCloseButton>
                    {goodsIframe?.name}
                </Modal.Header>
                <Modal.Body classes={style.modalIframeBody}>
                    {goodsIframe?.landing_link ? (
                        <Iframe
                            title={goodsIframe.name}
                            src={goodsIframe.landing_link}
                        />
                    ) : (
                        <ErrorMsg error={t('update_error')} />
                    )}
                </Modal.Body>
                {goodsIframe?.product_type?.id !== PRODUCT_TYPE_PROMOTION_DESCRIPTION && (
                    <Modal.Footer>
                        <Button
                            size="size44"
                            text={goodsInBasketDefaultPrice?.quantity ? t('market_in_basket') : t('Add to cart')}
                            disabled={isSubmitting}
                            onClick={handlerAddLandingGoodsToBasket}
                        />
                    </Modal.Footer>
                )}
            </Modal>
        </>
    )
}

export default Catalog
