import { createContext } from 'react'

export type ContextType = {
    isMobileFullScreen: boolean
    toClose: () => void
}

export const Context = createContext<ContextType>({
    isMobileFullScreen: false,
    toClose: () => {},
})
