import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { ITicketMessage, IUser } from 'interfaces'
import { API_HOST } from 'config/api'
import {
    Avatar,
    Image,
    TimeAgo,
    TextContent,
} from 'components'

import styleBlockComment from 'styles/modules/blockComment.module.css'
import style from './TicketMessage.module.css'

type TicketMessagePropType = {
    data: {
        user?: IUser
        message: ITicketMessage
    }
}

const TicketMessage: React.FC<TicketMessagePropType> = ({ data }) => {
    const IMAGE_RESIZE_PARAMS = { quality: 90, width: 600 }

    const { user, message } = data

    const { t } = useTranslation()

    const isOwn = useMemo(() => !!data?.message?.is_by_ticket_owner, [data])

    const avatarTpl = (
        <Avatar
            src={isOwn ? user?.photo : ''}
            width={54}
            height={54}
            name={isOwn ? user?.name : t('ui_support_create_ticket_title')}
            surname={isOwn ? user?.surname : ''}
        />
    )

    return (
        <div className={style.message}>
            {!isOwn && avatarTpl}

            <div className={cn(
                styleBlockComment.block,
                isOwn ? styleBlockComment.block_right : styleBlockComment.block_left,
            )}
            >
                <div className={cn(
                    styleBlockComment.content,
                    isOwn
                        ? styleBlockComment.content_colorOwn
                        : styleBlockComment.content_colorDefault,
                )}
                >
                    <div className={cn(
                        styleBlockComment.user,
                        isOwn ? styleBlockComment.user_colorWhite : '',
                    )}
                    >
                        {isOwn ? (
                            <>
                                {user?.name}
                                {' '}
                                {user?.surname}
                            </>
                        ) : (
                            t('ui_support_create_ticket_title')
                        )}
                    </div>

                    <div className={cn(
                        styleBlockComment.text,
                        isOwn ? styleBlockComment.text_colorWhite : '',
                    )}
                    >
                        {message.image && (
                            <Image
                                classesWrap={style.image}
                                src={API_HOST + message.image}
                                resize={IMAGE_RESIZE_PARAMS}
                            />
                        )}

                        <TextContent content={message.message} />
                    </div>

                    <div className={cn(
                        styleBlockComment.date,
                        isOwn ? styleBlockComment.date_colorWhite : '',
                    )}
                    >
                        <TimeAgo time={message.create_date} />
                    </div>
                </div>
            </div>

            {isOwn && avatarTpl}
        </div>
    )
}

export default TicketMessage
