import React, { useMemo } from 'react'
import { NavLink } from 'react-router-dom'

import { ITicketItem } from 'interfaces'
import { APP_URL } from 'config/app'
import { Block } from 'layout'
import { TicketStatusTag, TimeAgo, TextContent } from 'components'
import { parseTpl } from 'utils/helpers'
import style from './SupportItem.module.css'

type SupportItemPropType = {
    data: ITicketItem
}

const SupportItem: React.FC<SupportItemPropType> = ({ data }) => {
    const url = useMemo(() => parseTpl(APP_URL.ticket, {
        ':id': data.id,
    }, {
        prefix: '',
        suffix: '',
    }), [])

    return (
        <Block classes={style.item}>
            <div className={style.body}>
                <div className={style.title}>
                    <NavLink className={style.titleLink} to={url}>
                        <span className={style.textId}>
                            ID
                            {data.id}
                            {' '}
                        </span>
                        <span className={style.textTitle}>
                            {data?.category.name}
                        </span>
                    </NavLink>
                </div>

                <div className={style.text}>
                    <NavLink className={style.textLink} to={url}>
                        <TextContent
                            classesImg={style.textImage}
                            content={data?.last_message.message}
                            imgResize={{ width: 200 }}
                        />
                    </NavLink>
                </div>
            </div>

            <div className={style.info}>
                <div className={style.date}>
                    <TimeAgo time={data.create_date} />
                </div>

                <div className={style.status}>
                    <TicketStatusTag status={data.status_id} />
                </div>
            </div>
        </Block>
    )
}

export default SupportItem
