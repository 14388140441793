import { AxiosError } from 'axios'
import {
    UseQueryOptions,
    UseInfiniteQueryOptions,
    useQueryClient,
    useInfiniteQuery,
    useQuery,
    QueryFilters,
} from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { TMarketServiceError, TShopsStaticCategoryProps, TShopsStaticCategoryResponse } from 'services/MarketService'
import { QueryCacheKeys } from 'enums'
import { MarketService } from 'services'
import * as userSelectors from 'containers/User/user-selectors'
import { getRequestError } from 'utils/helpers'

export type TFetchFnParams = TShopsStaticCategoryProps
export type TFetchFnError = AxiosError<TMarketServiceError, TFetchFnParams>
export type TError = [string, TFetchFnError]
export type TQueryKey = [string, TFetchFnParams]
export type TQueryFnOpts = UseQueryOptions<
    TShopsStaticCategoryResponse,
    TError,
    TShopsStaticCategoryResponse,
    TQueryKey
>
export type TInfiniteQueryFnOpts = UseInfiniteQueryOptions<
    TShopsStaticCategoryResponse,
    TError,
    TShopsStaticCategoryResponse,
    TShopsStaticCategoryResponse,
    TQueryKey
>

const key = QueryCacheKeys.shopsStatic

/**
 * Хук API получить избранные магазины
 */
export default function useFetchShopsStatic(params: TFetchFnParams, opts: TQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useSelector(userSelectors.user)

    return useQuery([`${key}-${user.id}`, params], () => {
        return MarketService.fetchShopsStaticCategory(params)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    }, opts)
}

export function useFetchInfiniteShopsStatic(initParams: TFetchFnParams, opts: TInfiniteQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useSelector(userSelectors.user)

    return useInfiniteQuery([`${key}-${user.id}`, initParams], ({ pageParam = initParams }) => {
        return MarketService.fetchShopsStaticCategory(pageParam)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    }, {
        getNextPageParam: (lastPage, pages) => {
            return { ...initParams, offset: pages.length * lastPage.length }
        },
        ...opts,
    })
}

export function useRemoveQueriesDataShopsStatic() {
    const queryClient = useQueryClient()
    const user = useSelector(userSelectors.user)

    const remove = (params: TFetchFnParams, { exact = true, ...filters }: QueryFilters = {}) => {
        return queryClient.removeQueries([`${key}-${user.id}`, params], { exact, ...filters })
    }

    return {
        remove,
    }
}
