import React, { useContext, useMemo } from 'react'
import cn from 'classnames'

import { Context } from '../Context'
import style from './ModalBody.module.css'

export type ModalBodyPropType = {
    classes?: string
    background?: boolean | string
}

const ModalBody: React.FC<ModalBodyPropType> = ({
    children,
    classes,
    background,
}) => {
    const { isMobileFullScreen } = useContext(Context)

    const bgClass = useMemo(() => (background === true ? style.body_bgDefault : ''), [])
    const bgStyle = useMemo(() => (typeof background === 'string' ? background : undefined), [])

    return (
        <div
            className={cn(
                style.body,
                bgClass,
                classes,
                { [style.body_fullScreen]: isMobileFullScreen },
            )}
            style={{ backgroundColor: bgStyle }}
        >
            {children}
        </div>
    )
}

export default ModalBody
