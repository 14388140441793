import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { IUser, IHistoryMessage } from 'interfaces'
import { Avatar, Button, SvgResource } from 'components'
import { dateTimeFormat } from 'utils/helpers'
import style from './MessengerMediaBar.module.css'

type MessengerMediaBarPropType = {
    isOwn: boolean
    user: IUser
    data: IHistoryMessage
    onShare: () => void
    onDownload: () => void
    onClose: () => void
}

const MessengerMediaBar: React.FC<MessengerMediaBarPropType> = ({
    isOwn,
    user,
    data,
    onShare,
    onDownload,
    onClose,
}) => {
    const { i18n } = useTranslation()

    const date = useMemo(() => {
        return dateTimeFormat(data.createdAt, i18n.language)
    }, [data])

    const time = useMemo(() => {
        return dateTimeFormat(data.createdAt, i18n.language, { minute: '2-digit', hour: '2-digit' })
    }, [data])

    return (
        <div className={style.mediaBar}>
            <Avatar
                name={isOwn ? user.name : data.senderUser?.name}
                surname={isOwn ? user.surname : data.senderUser?.surname}
                src={isOwn ? user.photo : data.senderUser?.photoFullUrl}
                width={48}
                height={48}
            />
            <div className={style.info}>
                <div className={style.name}>
                    {isOwn ? user.name : data.senderUser?.name}
                    {' '}
                    {isOwn ? user.surname : data.senderUser?.surname}
                </div>
                <div className={style.date}>
                    {`${date}, ${time}`}
                </div>
            </div>
            <div className={style.actions}>
                <Button
                    classes={style.action}
                    styleType="transparent"
                    onClick={onShare}
                >
                    <SvgResource
                        isImgTag={false}
                        classes={style.iconShare}
                        resourceKey="ic_post_share_svg"
                        width={20}
                        height={20}
                    />
                </Button>
                <Button
                    classes={style.action}
                    styleType="transparent"
                    onClick={onDownload}
                >
                    <SvgResource
                        isImgTag={false}
                        classes={style.iconDownload}
                        resourceKey="ic_download_svg"
                        width={20}
                        height={20}
                    />
                </Button>
                <Button
                    classes={style.action}
                    styleType="transparent"
                    onClick={onClose}
                >
                    <span
                        className={style.iconClose}
                    />
                </Button>
            </div>
        </div>
    )
}

export default MessengerMediaBar
