import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { ITicket, ITicketItem, ITicketMessageProps } from 'interfaces'
import { AppDispatch } from 'store'
import { APP_URL, COUNTERS } from 'config/app'
import { ContentContainer, Block } from 'layout'
import {
    PageHeader,
    TicketStatusTag,
    UserMessageFormWrap,
    Loader,
    ErrorMsg,
} from 'components'
import { fetchCount, deleteCount } from 'containers/User/user-actions'
import * as userSelectors from 'containers/User/user-selectors'
import { useFetchTickets, useFetchTicket } from 'containers/Support/hooks'
import { TicketMessageAction } from 'form-actions'
import { scrollTop } from 'utils/helpers'
import useUserGUID from 'hooks/useUserGUID'
import { TicketMessage } from './components'
import style from './Ticket.module.css'

const Ticket: React.FC = () => {
    const { id } = useParams<{ id: string }>()
    const { t } = useTranslation()
    const { i18n } = useTranslation()
    const history = useHistory()
    const dispatch: AppDispatch = useDispatch()
    const guid = useUserGUID()

    const user = useSelector(userSelectors.user)
    const counts = useSelector(userSelectors.counts)

    const [ticket, setTicket] = useState<ITicket>()
    const [ticketGUID, setTicketGUID] = useState<string>()

    const [ticketMessageProps] = useState<ITicketMessageProps>({
        id: Number(id),
        generated_user_id: guid,
        message: '',
    })

    const [error, setError] = useState<string>()

    const { isInitialLoading: isLoadingTickets, data: dataTickets, error: errorTickets } = useFetchTickets({ guid })

    const { isInitialLoading: isLoadingTicket, data: dataTicket, error: errorTicket } = useFetchTicket({
        id,
        guid: ticketGUID!,
        lang: user ? user.language.iso_code : i18n.language,
    }, {
        enabled: !!ticketGUID,
        staleTime: 0,
    })

    const messages = useMemo(() => {
        return ticket?.messages?.length
            ? ticket.messages.sort((a, b) => (a.id > b.id ? 1 : -1))
            : []
    }, [ticket])

    const handlerClickBack = () => {
        history.push(APP_URL.support)
    }

    function getCurrentTicketGUID(ticketList: ITicketItem[]) {
        const currentTicket = id ? ticketList.find((item) => item.id === Number(id)) : undefined

        if (currentTicket) {
            setTicketGUID(currentTicket.generated_user_id)
        } else {
            setError(t('nothing_found'))
        }
    }

    function fetchCountAction() {
        dispatch(fetchCount({ counter: 'support_count' })).catch(() => {})
    }

    function resetCountAction() {
        dispatch(deleteCount({ counter: 'support_count' })).catch(() => {})
    }

    useEffect(() => {
        const scrollTimerId = setTimeout(scrollTop)

        if (user) {
            fetchCountAction()
        }

        return () => {
            clearTimeout(scrollTimerId)
        }
    }, [])

    useEffect(() => {
        if (dataTickets) {
            getCurrentTicketGUID(dataTickets)
        }
    }, [dataTickets])

    useEffect(() => {
        if (dataTicket) {
            setTicket(dataTicket)

            if (counts?.[COUNTERS.support]) {
                resetCountAction()
            }
        }
    }, [dataTicket])

    return (
        <ContentContainer size="half">
            <PageHeader
                title={ticket?.category.name}
                onClickBack={handlerClickBack}
            >
                <TicketStatusTag status={ticket?.status_id} />
            </PageHeader>

            {(isLoadingTickets || isLoadingTicket) && (
                <Loader />
            )}

            {(error || !!errorTickets?.length || !!errorTicket?.length) && (
                <ErrorMsg error={error! || errorTickets![0] || errorTicket![0]} />
            )}

            {!!messages.length && (
                <Block classes={style.ticket}>
                    <div className={style.ticketItems}>
                        {messages.map((item) => (
                            <TicketMessage data={{ user, message: item }} key={item.id} />
                        ))}
                    </div>

                    <UserMessageFormWrap classes={style.formFieldWrap} user={user}>
                        <TicketMessageAction
                            ticketMessageProps={ticketMessageProps}
                        />
                    </UserMessageFormWrap>
                </Block>
            )}
        </ContentContainer>
    )
}

export default Ticket
