import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { APP_URL } from 'config/app'
import { Container, PageContainer } from 'layout'
import {
    NavBar,
    Logo,
    Link,
    MenuNavMainPublic,
    Footer,
} from 'components'
import { Support, Ticket } from 'containers'
import styleCommon from 'styles/modules/common.module.css'
import style from './SupportPage.module.css'

/**
 * Страница обращений в поддержку
 */
const SupportPage: React.FC = () => {
    return (
        <>
            <header>
                <NavBar>
                    <Link className={styleCommon.navBarLogoWrap} url={APP_URL.auth}>
                        <Logo />
                    </Link>
                    <MenuNavMainPublic />
                </NavBar>
            </header>

            <Container>
                <PageContainer classes={style.pageContainer}>
                    <Switch>
                        <Route path={APP_URL.support} component={Support} exact />
                        <Route path={APP_URL.ticket} component={Ticket} />
                    </Switch>
                </PageContainer>
            </Container>

            <Footer />
        </>
    )
}

export default SupportPage
