import {
    ITicket,
    ITicketItem,
    ITicketCategory,
    ITicketProps,
    ITicketMessageProps,
} from 'interfaces'
import { API_URL } from 'config/api'
import requestClient from 'utils/requestClient'
import { parseTpl } from 'utils/helpers'

export type TSupportServiceError = {
    status: number
    type: string
    title: string // example: An error occurred
    detail: string // example: Not Found
}

export type TTicketsProps = {
    guid: string
}

export type TTicketProps = {
    id: string
    guid: string
    lang?: number | string // id | iso_code
}

export type TTicketCategoriesProps = {
    // for not authenticated user
    lang?: number
    lang_short?: string
}

export type TAddTicketProps = { lang?: number } & ITicketProps

export type TAddTicketMessageProps = ITicketMessageProps

export type TTicketsResponse = ITicketItem[]

export type TTicketResponse = ITicket

export type TTicketCategoriesResponse = ITicketCategory[]

export type TAddTicketResponse = ITicketItem

export type TAddTicketMessageResponse = {
    message_id: number
}

/**
 * API support
 */
class SupportService {
    static fetchTickets({ guid }: TTicketsProps) {
        return requestClient<TTicketsResponse>(`${API_URL.ticket}/${guid}`)
    }

    static fetchTicket({ id, guid, lang }: TTicketProps) {
        return requestClient<TTicketResponse>(`${API_URL.ticket}/${id}/${guid}`, { params: { lang } })
    }

    static fetchTicketCategories({ lang, lang_short }: TTicketCategoriesProps = {}) {
        return requestClient<TTicketCategoriesResponse>(API_URL.ticketCategory, { params: { lang, lang_short } })
    }

    static addTicket(params: TAddTicketProps) {
        return requestClient<TAddTicketResponse>(API_URL.ticket, { method: 'post', data: params })
    }

    static addTicketMessage({ id, ...params }: TAddTicketMessageProps) {
        const url = parseTpl(API_URL.ticketMessage, { id })
        return requestClient<TAddTicketMessageResponse>(url, { method: 'post', data: params })
    }
}

export default SupportService
