import React from 'react'

import { SvgResource } from 'components'
import style from './RegistrationFormWrap.module.css'

const RegistrationFormWrap: React.FC = ({ children }) => {
    return (
        <div className={style.registration}>
            <div className={style.header}>
                <div className={style.logoWrap}>
                    <SvgResource
                        isImgTag={false}
                        classes={style.logo}
                        resourceKey="auth_kickback_logo"
                        width={200}
                        height={100}
                    />
                </div>
            </div>

            <div className={style.body}>
                {children}
            </div>
        </div>
    )
}

export default RegistrationFormWrap
