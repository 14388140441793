import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { APP_URL } from 'config/app'
import { RegistrationUser } from 'containers'
import { Footer } from 'components'
import style from './HomePage.module.css'

/**
 * Страница регистрации данных
 *
 * @name HomePage
 * @memberOf module:Layouts
 */
const HomePage: React.FC = () => {
    const { t } = useTranslation()

    return (
        <>
            <div className={style.page}>
                <div className={style.columnLeft}>
                    <div className={style.content}>
                        {t('Socializing and shopping')}
                        <br />
                        {t('With pleasure and cashbacks')}
                    </div>
                </div>

                <div className={style.columnRight}>
                    <div className={style.content}>
                        <Switch>
                            <Route
                                exact
                                path={APP_URL.registrationUser}
                                component={RegistrationUser}
                            />
                            <Redirect from="*" to={APP_URL.registrationUser} />
                        </Switch>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default HomePage
