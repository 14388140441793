import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReactModal from 'react-modal'
import cn from 'classnames'

import { addModal, removeModal } from 'containers/App/app-actions'
import * as AppSelectors from 'containers/App/app-selectors'
import { getId } from 'utils/helpers'
import ModalHeader, { ModalHeaderPropType } from './ModalHeader/ModalHeader'
import ModalBody, { ModalBodyPropType } from './ModalBody/ModalBody'
import ModalFooter, { ModalFooterPropType } from './ModalFooter/ModalFooter'
import { ModalTopBar } from './components'
import { ContextType, Context } from './Context'
// eslint-disable-next-line
import styleOverlay from 'styles/modules/overlay.module.css'
import style from './Modal.module.css'

export type ModalPropType = {
    isOpen: boolean
    size?: 'small' | 'smallCenter' | 'small2' | 'small2Center' | 'medium' | 'mediumCenter' | 'large' | 'fullWidth'
    mobileFullScreen?: boolean
    classes?: string
    classesOverlay?: string
    topBarContent?: JSX.Element
    onClose?: () => void
}

type ModalComposition = {
    Header: React.FC<ModalHeaderPropType>
    Body: React.FC<ModalBodyPropType>
    Footer: React.FC<ModalFooterPropType>
}

const Modal: React.FC<ModalPropType> & ModalComposition = ({
    children,
    isOpen,
    size = 'medium',
    mobileFullScreen = false,
    classes,
    classesOverlay,
    topBarContent,
    onClose = () => {},
}) => {
    const dispatch = useDispatch()

    const modals = useSelector(AppSelectors.modals)

    const [modalId] = useState(getId(true))
    const [context] = useState<ContextType>({
        isMobileFullScreen: mobileFullScreen,
        toClose: onClose,
    })

    const isHiddenModal = useMemo(() => {
        const len = modals.length
        return !!len && modals[len - 1] !== modalId
    }, [modals])

    const handlerClose = () => {
        context.toClose()
    }

    const handlerAfterOpen = () => {
        dispatch(addModal(modalId))
    }

    const handlerAfterClose = () => {
        dispatch(removeModal(modalId))
    }

    return (
        <>
            <ReactModal
                isOpen={isOpen}
                id={String(modalId)}
                className={cn(
                    style.modal,
                    style[size],
                    classes,
                    {
                        [style.modal_hidden]: isHiddenModal,
                        [style.modal_mobileFullScreen]: mobileFullScreen,
                    },
                )}
                overlayClassName={{
                    base: cn(styleOverlay.overlay, classesOverlay, { [styleOverlay.hide]: isHiddenModal }),
                    afterOpen: styleOverlay.show,
                    beforeClose: styleOverlay.hide,
                }}
                onRequestClose={handlerClose}
                onAfterOpen={handlerAfterOpen}
                onAfterClose={handlerAfterClose}
            >
                <Context.Provider value={context}>
                    {children}
                </Context.Provider>
            </ReactModal>

            {topBarContent && (
                <ModalTopBar
                    isOpen={isOpen}
                >
                    {topBarContent}
                </ModalTopBar>
            )}
        </>
    )
}

Modal.Header = ModalHeader
Modal.Body = ModalBody
Modal.Footer = ModalFooter

export default Modal
